import React, { useState } from 'react';
import { useEffect } from 'react';
import { getScrollRestorationStore } from '../../../util/scrollRestorationStore';
import { withRouter } from 'react-router-dom';

const PUSH = 'PUSH',
	POP = 'POP';

const ScrollRestoration = ({history = {}, widgetsData = ''} = {}) => {
	const { action = '', location = {} } = history,
		{ pathname = '' } = location,
		[ scrollRestorationStore ] = useState(getScrollRestorationStore()),
		[ pageCount, setPageCount ] = useState(scrollRestorationStore.getPageIndex() || 0);

	useEffect(() => {
		if (pageCount && window && document) {
			const onScroll = () => {
				const scrollPositionY = document.documentElement.scrollTop || document.body.scrollTop || 0;
	
				scrollRestorationStore.addPageScrollPosition(pathname, pageCount, scrollPositionY);
			};
			window.addEventListener("scroll", onScroll);
			onScroll();
			
			return () => {
				window.removeEventListener("scroll", onScroll);
			}
		}
	}, [pageCount]);

	useEffect(() => {
		const storePathname = scrollRestorationStore.getPathname() || '';

		if (storePathname !== pathname) {
			scrollRestorationStore.setPageIndex(pageCount + 1);
			setPageCount(scrollRestorationStore.getPageIndex());
		}

		scrollRestorationStore.setPathname(pathname);
	}, [pathname]);

	useEffect(() => {
		if (widgetsData && Array.isArray(widgetsData) && widgetsData.length && action === POP) {
			const scrollPositionY = scrollRestorationStore.getPageScrollPosition(pathname, pageCount);
			
			console.log('**&&**', scrollPositionY);
			if (window && scrollPositionY > 100) {
				window.scrollTo({ top: scrollPositionY});
			}
		}
		// 
	}, [widgetsData]);

	return <></>;
}

export default withRouter(ScrollRestoration);